import React from 'react';

const Code = () => {
  return (
    <div className="tile is-ancestor mt-4">
      <div className="tile is-vertical">
        {/* Rheaply  */}
        <div className="my-4">
          <h3 className="font-headline my-2">Rheaply</h3>
        </div>
        <div className="mb-12 ml-4">
          <div className="mb-4">
            <p>
              Rheaply introduces re-commerce for every business. Save time and
              money by keeping assets and materials in constant reuse. By
              combining smart inventory management and connected nationwide
              marketplaces, Rheaply puts reuse back on the table.
            </p>
          </div>

          <div className="tile">
            <img src="/img/work/rheaply.png" alt="Rheaply marketplace" />
          </div>
        </div>

        <hr className="my-2" />

        {/* Inventables  */}
        <div className="my-4">
          <h3 className="font-headline my-2">Inventables</h3>
        </div>
        <div className="mb-12 ml-4">
          <div className="mb-4">
            <p>
              Inventables is a pioneering company at the forefront of the maker
              revolution, empowering individuals and businesses alike to unleash
              their creativity through CNC machines and intuitive software.
              Whether you're a seasoned professional or a passionate hobbyist,
              Inventables provides the resources and support needed to turn
              ideas into reality, revolutionizing the way we design, prototype,
              and produce.
            </p>
            <p>
              While at Invetables, I worked on their software Easel and made
              instructions for the new X-Carve Pro. I worked closely with the
              mechanical engineer to ensure Easel was operating how it should
              with the X-Carve Pro.
            </p>
          </div>

          <div className="flex tile">
            <div className="tile is-parent " style={{ flexGrow: '2' }}>
              <article className="tile is-child">
                <img src="/img/work/easel.gif" alt="Easel" />
              </article>
            </div>
            <div className="tile is-parent ">
              <article className="p-4 drop-shadow">
                <img
                  src="/img/work/Inventables_Easel_CNC_Design_Software-Cheat_Sheet.jpeg"
                  alt="CS App Load Screen"
                />
              </article>
            </div>
          </div>
        </div>

        <hr className="my-2" />

        <div className="my-4">
          <h3 className="font-headline my-2">Trunk Club</h3>
        </div>
        <div className="mb-12 ml-4">
          <div className="flex mb-6">
            <p>
              Trunk Club, a Nordstrom Company, redefined personalized styling
              with a seamless blend of technology and expert guidance. Their
              mission was to make effortless style accessible to all, offering
              curated selections of premium clothing, shoes, and accessories
              tailored to your individual taste and lifestyle. Clients enjoyed a
              uniquely personalized shopping experience, where dedicated
              stylists hand-select items to create customized trunks delivered
              right to your door. Trunk Club offered unparalleled convenience
              that elevated the art of personal style, empowering one to look
              and feel their best with every wardrobe choice.
            </p>
          </div>
          <div className="flex justify-between flex-row">
            <div className="drop-shadow ">
              <img src="/img/work/tc-business.jpg" alt="Trunk Club" />
            </div>
            <div className="drop-shadow">
              <article className="p-4">
                <img src="/img/work/tc-gift.jpg" alt="Trunk Club" />
              </article>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-2" />

      <div className="mb-12">
        <div className="my-4">
          <h3 className="font-headline mt-2">
            Camp Shutout & PAWS Goalkeeping
          </h3>
        </div>
        <div className="flex justify-between flex-row">
          <div className="md:w-1/2">
            <div className="flex">
              <article className="p-4 tile is-parent">
                <img src="/img/work/cs_load.jpeg" alt="CS App Load Screen" />
              </article>
              <article className="p-4 tile is-parent">
                <img src="/img/work/cs_home.jpeg" alt="CS App Home Screen" />
              </article>
            </div>
            <div className="flex">
              <article className="p-4 tile is-parent">
                <img
                  src="/img/work/cs_schedule.jpeg"
                  alt="CS App Schedule Screen"
                />
              </article>
              <article className="p-4 tile is-parent">
                <img src="/img/work/cs_games.jpeg" alt="CS App Home Screen" />
              </article>
            </div>
          </div>
          <div className="md:w-1/2">
            <article className="p-4 drop-shadow">
              <img src="/img/work/PAWS.jpg" alt="PAWs" />
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Code;
