import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero-all';
import Footer from '../components/Footer';
import Tabs from '../components/Tabs/Tabs';

import Built from './made/Built';
import Code from './made/Code';
import Visual from './made/Visual';

const WorkPage = () => {
  return (
    <Layout>
      <Hero image="/img/hero/develop.jpeg" title="I made &lowbar;." />
      <section className="container max-w-4xl mx-auto px-4">
        <div className="container mb-12">
          <h2 className="font-headline font-semibold mb-2">
            From code to wood
          </h2>
          <p className="mb-6">
            Embracing the label of a polymath, my insatiable curiosity drives me
            to delve into uncharted territories and be curious while obtaining
            diverse skills. Although I initially ventured into the realm of
            computer science during my college years, the problem solving wasn't
            enough and I craved creativity, prompting me to study visual
            communications as a second major.
          </p>
          <p className="mb-6">
            Constantly intrigued by the intricate mechanisms of our world and
            the underlying patterns shaping human behavior, I am not confined to
            a singular domain. From coding to woodworking, the drive to
            understand why and how things work has led me to explore a wide
            range of interests and tinkering opportunities.
          </p>
          <p className="mb-6">
            This page is my little showcase of projects that not only show off
            what I can do but also highlight the awesome lessons I've picked up
            along the way. It's all about growing, staying dynamic, and keeping
            the creative vibes flowing.
          </p>
        </div>

        <Tabs>
          <div label="code" url="code">
            <Code />
          </div>
          <div label="built" url="built">
            <Built />
          </div>
          <div label="visual creations" url="visual">
            <Visual />
          </div>
        </Tabs>

        <Footer />
      </section>
    </Layout>
  );
};

export default WorkPage;
